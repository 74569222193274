export default {
    common: {
        "sign_up": "註冊",
        "login": "登入",
        "log_in": "登入",
        "register": "註冊r",
        "logout": "登出",
        "profile": "個人資料",
        "step": "步驟",
        "type": "類型",
        "value": "值",
        "cancel": "取消",
        "upload": "上傳",
        "close": "關閉",
        "update": "更新",
        "submit": "提交",
        "continue": "繼續",
        "add": "加",
        "save": "儲存",
        "edit": "編輯",
        "back": "返回",
        "status": "狀態",
        "copy": "複製",
        "details": "詳情",
        "assign": "分配",
        "password": "密碼",
        "user_id": "用戶帳號",
        "contact": "聯絡",
        "address": "地址",
        "address_1": "香港新界葵涌三號貨櫃碼頭13樓13103N室",
        "qr_reader": "二維碼閱讀器",
        "register_text_1": "點擊註冊，即表示您確認已閱讀並接受我們公司的",
        "terms_conditions": "條款及細則",
        "terms_and_conditions": "條款及細則",
        "privacy_policy": "隱私條例",
        "please_select": "請選擇",
        "select": "選擇",
        "must_be_over_18_years_old": "必須年滿18歲",
        "choose_picture": "選擇圖片",
        "instagram_tooltip_text_1": "為了申請任務並令推薦人獲得獎金，您的Instagram帳戶必須是創作者或商業帳戶。",
        "instagram_tooltip_text_2": "如何切換到創作者帳戶？請查看",
        "instagram_tooltip_text_3": "如何切換到商業帳戶？請查看",
        "instagram_tooltip_text_4": "這裡",
        "wallet_balance_tooltip_1": "錢包餘額:",
        "wallet_balance_tooltip_2": "您的錢包餘額將在採取任何行動後的14個工作日內更新",
        "wallet_balance_tooltip_3": "當餘額達到港幣1,000元時，您可以要求將餘額提取至您的銀行帳戶。",
        "wallet_balance_tooltip_4": "錢包餘額",
        "wallet_balance_tooltip_5": "",
        "wallet_balance_tooltip_6": "",
    }
};
