<template>
    <div id="loading-box" class="loading-box">
        <div :id="loadingType">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadingShow",
        props:{
            loadingType:{
                type: String,
                default: 'preloader_4'
            }
        }
    }
</script>

<style scoped>
    /* display: none || flex */
    .loading-box{
        width: 100%; height: 100%; display: none; align-items: center; justify-content: center;
        position: fixed; z-index: 999999; top: 0; left: 0; background-color: rgba(0,0,0,.5);
    }
</style>
