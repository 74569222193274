import axios from 'axios';
import { Message ,Loading} from 'element-ui';
import Vue from 'vue'
import i18n from "@/lang";
Vue.use(i18n)
import { isLogged, setLogged, removeToken, loadingFns } from '@/utils/auth';
import store from "@/store";

import md5 from 'js-md5'
import { Base64 } from 'js-base64'
const tokenTime = Date.now()
const token = md5(Base64.encode('beltnroad'+tokenTime))

const baseURL = process.env.VUE_APP_URL;

const service = axios.create({
    baseURL,
    timeout: 100000, // Request timeout
});

function loadingFn(){
    let loading = Loading.service({
        lock: true,
        spinner: 'fa fa-spinner fa-pulse font-32 color-000000'
    });
    return loading;
}

// Request intercepter
service.interceptors.request.use(
    config => {
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`; // Set token
        }
        if (tokenTime && token) {
            config.headers['token'] = token; // Set JWT token
            config.headers['time'] = tokenTime; // Set JWT token
        }

        // if (!isLogged() && !config.isLogged){
        //     location.reload();
        //     return
        // }else{
        //     if (isLogged())
        //         setLogged(1);
        // }

        if (config.method=='post') {
            if (config.loading !== false && config?.data?.loading !== false){
                // console.log(config.loading !== false)
                loadingFns('flex')
            }
        } else {
            if (config.params && config.params.loading == true){
                loadingFns('flex')
            }
        }
        return config;
    },
    error => {
        loadingFns('none')
        Promise.reject(error);
    }
);

// response pre-processing
service.interceptors.response.use(
    response => {
        loadingFns('none')
        let configData = response.config && response.config.data && response.config.url != '/image/upload' ? typeof response.config.data == Object ? response.config.data : JSON.parse(response.config.data) : {}
        if (
            (configData.alert || configData.alert == undefined) && (response.data.status === 0 && response.data.message || response.data.status == '0' && response.data.message)
        ) {
            Message({
                message:response.data.message,
                type: 'error',
                duration: 15000,
                showClose: true
            });
        }
        return response.data;
    },
    error => {
        loadingFns('none')
        // console.log(error.response.status)
        let message = error.message;
        if (error.response?.data && error.response?.data.errors) {
            message = error.response.data.errors;
        } else if (error.response?.data && error.response?.data.error) {
            message = error.response.data.error;
        }
        if (error.response.status==401){
            removeToken();
            location.reload();
        }

        Message({
            message: message.indexOf(' ')!=-1 ? message : i18n.t('message.'+message) ,
            type: 'error',
            duration: 5000,
            showClose: true
        });
        return Promise.reject(error);
    }
);

export default service;

