<template>
    <div class="width-100">
        <top-menu></top-menu>
        <div class="page-content">
            <keep-alive :include="cachedViews">
                <router-view :key="key" />
            </keep-alive>
        </div>
        <footer-bottom></footer-bottom>
        <loading-show></loading-show>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import TopMenu from "@/layout/components/TopMenu";
    import FooterBottom from "@/layout/components/Footer";
    import LoadingShow from "@/components/LoadingShow";
    import { loadingFns, setCookies, getCookies } from "@/utils/auth";

    export default {
        name: "LayoutIndex",
        components:{ TopMenu, FooterBottom, LoadingShow },
        computed: {
            cachedViews() {
                return this.$store.state.tagsView.cachedViews;
            },
            key() {
                return this.$route.fullPath;
            },
        },
        created() {
            let that = this;
            let lang = getCookies('lang')
            let windLang = navigator.browserLanguage || navigator.language || navigator.userLanguage

            if (windLang && that.$route.path!='/news/detail'){
                if (!lang){
                    let tempLang = windLang == 'zh-CN' ? 'zh' : windLang == 'zh-TW' || windLang == 'zh-HK' ? 'tc' : 'en'
                    setCookies({ key: 'lang', seconds: 60*60, value: tempLang } )
                    that.$i18n.locale = tempLang;
                    that.$store.dispatch('app/setLanguage', tempLang);
                }
            }
            that.getInnerWidth()
        },
        methods:{
            getInnerWidth(){
                let that = this;
                let defWdith = 1920
                let mWdith = window.innerWidth
                if (mWdith > defWdith){
                    let bodyWidth = Number( ( ((mWdith - defWdith) / defWdith).toFixed(4) ) ) * 100 + 100
                    console.log(bodyWidth)
                    document.getElementById('beltnroad-body').style.zoom = bodyWidth + '%'
                }else{
                    document.getElementById('beltnroad-body').style.zoom = '100%'
                }
            }
        },
        watch:{
            $route(to,from){
                if (to.fullPath=='/'){
                    loadingFns()
                }
            }
        }
    }
</script>

<style>
    .page-content{ width: 100%; margin-top: var(--header-height);}

    @media (max-width: 767px) {
        .page-content { margin-top: 75px;}
    }
</style>
