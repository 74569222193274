import Layout from "@/layout/index";

const pageRoutes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('@/views/Index'),
                name: 'HomePage',
                meta: { auth: false},
            },
        ],
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'news',
                component: () => import('@/views/News/Index'),
                name: 'NewsPage',
                meta: { auth: false}
            },
        ],
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'news/detail',
                component: () => import('@/views/News/Detail'),
                name: 'NewsDetailPage',
                meta: { auth: false}
            },
        ],
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'media-library',
                component: () => import('@/views/MediaLibrary/Index'),
                name: 'MediaLibraryPage',
                meta: { auth: false}
            },
        ],
    },
    // {
    //     path: '/',
    //     component: Layout,
    //     children: [
    //         {
    //             path: 'contact-us',
    //             component: () => import('@/views/ContactUs/Index'),
    //             name: 'ContactUsPage',
    //             meta: { auth: false}
    //         },
    //     ],
    // },
]


export default pageRoutes;
