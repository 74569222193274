<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',
    }
</script>
<style>
    .el-scrollbar > .el-scrollbar__bar{ opacity: 1!important;}
</style>
