<template>
    <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
        <div class="e-lang-box cursor-pointer">
<!--            <img src="/assets/images/icon/lang-cecece.png" style="width: 18px; height: 18px;">-->
<!--            <span class="e-lang-text font-14">{{$i18n.locale==='en'?'English':$i18n.locale==='zh'?'简体':'繁體'}}</span>-->
            <span class="e-lang-text font-12">{{$i18n.locale=='zh'?'cn':$i18n.locale}}</span>
<!--            <i class="el-icon-arrow-down font-12" />-->
        </div>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="$i18n.locale==='zh'" command="zh">
                简体
            </el-dropdown-item>
            <el-dropdown-item :disabled="$i18n.locale==='tc'" command="tc">
                繁體
            </el-dropdown-item>
            <el-dropdown-item :disabled="$i18n.locale==='en'" command="en">
                English
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    export default {
        name: "ChangeLanguage",
        methods:{
            handleSetLanguage(lang) {
                let that = this;
                that.$i18n.locale = lang;
                that.$store.dispatch('app/setLanguage', lang);
                that.$emit('refreshFn')
            },
        }
    }
</script>

<style scoped>
    .e-lang-box{ height: 100%; display: flex; align-items: center; margin-left: 10px;}
    .e-lang-text{ font-weight: bold; color: #4d4d4d; border: 1px solid #4d4d4d; border-radius: 100%; width: 24px; height: 24px; line-height: 24px; text-transform: uppercase; text-align: center;}
    @media (max-width: 768px) {
        .e-lang-box{ margin: 0 15px 15px 30px;}
        .e-lang-text{ border-color: #ffffff; color: #ffffff;}
    }
</style>
