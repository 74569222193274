<template>
    <div class="cfd-header">
        <div class="cfd-content bel-nav-box">
            <div class="cfd-flex">
                <router-link to="/" class="logo">
                    <img src="/assets/images/logo/logo.png" alt="">
                </router-link>
                <div class="text-right top-nav">
                    <template v-for="(item, index) in common.navigationList">
                        <router-link v-if="item.osnl_url && item.osnl_url.indexOf('#')==-1" :class="`${item.osnl_url == $route.path || item.osnl_url == '/news' && $route.path=='/news/detail' ? 'a-active' : ''}`"
                                     :to="item.osnl_url" @click.native="handLink(item.osnl_url)" :key="index">{{getNavTitle(item)}}</router-link>
                        <a v-else @click="goIdBox(item.osnl_url)" href="javascript:;" :key="index">{{getNavTitle(item)}}</a>
                    </template>

                    <!--                    <a href="javascript:;"><img src="/assets/images/relt/search_03.png" alt=""></a>-->
                    <change-language ref="refLang"></change-language>
                </div>
                <div class="width-100 mobile-menu">
                    <a @click="clickMenu" href="javascript:;">
                        <img src="/assets/images/menu.png" alt="">
                    </a>
                </div>
            </div>

            <div :class="`${mobileStatus?'mobile-modmax-active':''} mobile-modmax-box`" @click="clickMenu"></div>
            <div :class="`${mobileStatus?'menu-active':''} mobile-menu-box`">
                <div class="width-100 text-right clearfix">
                    <a @click="clickMobileLang('zh')" href="javascript:;" :class="`${$i18n.locale=='zh'?'lang-active':''} lang-mobile-top`">简体</a>
                    <a @click="clickMobileLang('tc')" href="javascript:;" :class="`${$i18n.locale=='tc'?'lang-active':''} lang-mobile-top`">繁體</a>
                    <a @click="clickMobileLang('en')" href="javascript:;" :class="`${$i18n.locale=='en'?'lang-active':''} lang-mobile-top`">English</a>
                    <a @click="clickMenu" class="close-nav float-right" href="javascript:;">
                        <img src="/assets/images/close.png" alt="">
                    </a>
                </div>

                <template v-for="(item, index) in common.navigationList">
                    <router-link v-if="item.osnl_url && item.osnl_url.indexOf('#')==-1" :class="`${item.osnl_url == $route.path || item.osnl_url == '/news' && $route.path=='/news/detail' ? 'a-active' : ''}`"
                                 :to="item.osnl_url" @click.native="handLink(item.osnl_url)" :key="index">{{getNavTitle(item)}}</router-link>
                    <a v-else @click="goIdBox(item.osnl_url)" href="javascript:;" :key="index">{{getNavTitle(item)}}</a>
                </template>

                <!--                <a href=""><img src="/assets/images/relt/search_03.png" alt=""></a>-->
                <!--                <change-language @refreshFn="clickMenu"></change-language>-->
            </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ChangeLanguage from "@/layout/components/ChangeLanguage";
    export default {
        name: "LeftMenu",
        components:{ ChangeLanguage },
        computed: {...mapGetters(['common'])},
        data(){
            return{
                mobileStatus: false,
                menuList:[
                    {title: '首頁', osnl_url: '/'},
                    {title: '關於本會', osnl_url: '#gybh'},
                    // {title: '架構', osnl_url: '/pay-deliver'},
                    {title: '成為會員', osnl_url: '#cwhy'},
                    {title: '本會動態', osnl_url: '/news'},
                    {title: '媒體庫', osnl_url: '/media-library'},
                    {title: '聯絡我們', osnl_url: '#llwm'},
                ],
            }
        },
        created() {
        },
        methods:{
            clickMobileLang(lang){
                let that = this;
                that.$refs.refLang.handleSetLanguage(lang)
                that.clickMenu();
            },
            getNavTitle(item){
                let that = this;
                return that.$i18n.locale=='zh' ? item.osnl_name_zh : that.$i18n.locale=='tc' ? item.osnl_name_tc : item.osnl_name_en
            },
            clickMenu(){
                let that = this;
                that.mobileStatus = !that.mobileStatus
                document.getElementById('beltnroad-body').style.overflow = that.mobileStatus ? 'hidden' : 'auto'
            },
            handLink(path){
                let that = this;
                if (that.$route.path == path){
                    scroll({ top: 0, behavior: "smooth" });
                }
                that.mobileStatus = false;
                document.getElementById('beltnroad-body').style.overflow = that.mobileStatus ? 'hidden' : 'auto'
            },
            goIdBox(id){
                let that = this;
                id = id.substring(1);
                let doc = document.getElementById(id);
                if (doc){
                    let offsetTop = doc.offsetTop - 110;
                    scroll({ top: offsetTop, behavior: "smooth" });
                }else {
                  that.$router.push({name:'HomePage',params:{go_id:id}})
                }
                that.mobileStatus = false;
                document.getElementById('beltnroad-body').style.overflow = that.mobileStatus ? 'hidden' : 'auto'
            },
        },
        mounted() {
            let that = this;
            window.addEventListener('resize', () => {
                if (document.body.clientWidth>768){
                    that.mobileStatus = false;
                    document.getElementById('beltnroad-body').style.overflow =  'auto'
                }
            })
        },
        watch:{
            $route(to,form){
                let that = this;
            }
        }
    }
</script>

<style scoped>
    .lang-mobile-top{ margin: 15px; margin-right: 0; margin-top: 20px; float: left;}
    .lang-mobile-top:first-child{ margin-left: 30px;}
    .lang-mobile-top.lang-active{ color: #979797;}
</style>
