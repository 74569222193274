<template>
    <div class="text-center icps">
        <div class="cfd-content clearfix">
            <div class="bottom-nav-box">
                <template v-for="(item, index) in common.navigationList">
                    <router-link v-if="item.osnl_url && item.osnl_url.indexOf('#')==-1"
                                 :to="item.osnl_url" :key="index">{{getNavTitle(item)}}</router-link>
                    <a v-else @click="goIdBox(item.osnl_url)" href="javascript:;" :key="index">{{getNavTitle(item)}}</a>
                </template>
            </div>
            <span style="float: right;">{{icp}}</span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "FooterPage",
        computed:{...mapGetters(['common'])},
        data(){
            return{
                menuList:[
                    {title: '首頁', osnl_url: '/'},
                    {title: '關於本會', osnl_url: '#gybh'},
                    // {title: '架構', osnl_url: '/pay-deliver'},
                    {title: '成為會員', osnl_url: '#cwhy'},
                    {title: '本會動態', osnl_url: '/news'},
                    {title: '媒體庫', osnl_url: '/media-library'},
                    {title: '聯絡我們', osnl_url: '#llwm'},
                ],
                icp:null
            }
        },
        created() {
            let that = this;
            let company_information = that.common.common.company_information
            if (company_information){
                let tempIcp = '';
                if (company_information.master_text.icp.indexOf('{year}')>-1){
                    tempIcp = company_information.master_text.icp.replace('{year}',new Date().getFullYear())
                }else{
                    tempIcp = company_information.master_text.icp
                }
                that.icp = tempIcp
            }
        },
        methods:{
            goIdBox(id){
                let that = this;
                id = id.substring(1);
                let doc = document.getElementById(id);
                if (doc){
                    let offsetTop = doc.offsetTop - 110;
                    scroll({ top: offsetTop, behavior: "smooth" });
                    that.mobileStatus = false;
                }else {
                    that.$router.push({name:'HomePage',params:{go_id:id}})
                }
            },
            getNavTitle(item){
                let that = this;
                return that.$i18n.locale=='zh' ? item.osnl_name_zh : that.$i18n.locale=='tc' ? item.osnl_name_tc : item.osnl_name_en
            },
        }
    }
</script>
