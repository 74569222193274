import request from '@/utils/request';

// export function userWallet(query) {
//     return request({
//         url: '/wallet/info',
//         method: 'get',
//         params: query
//     });
// }
//
// export function userCreate(data) {
//     return request({
//         url: '/user/create',
//         method: 'post',
//         data: data,
//     });
// }

export function systemConfig(query) {
    return request({
        url: '/system_config',
        method: 'get',
        params: query
    });
}

export function navigationList(query) {
    return request({
        url: '/navigation_list',
        method: 'get',
        params: query
    });
}

export function scrollList(query) {
    return request({
        url: '/scroll_list',
        method: 'get',
        params: query
    });
}

export function newsList(query) {
    return request({
        url: '/news_list',
        method: 'get',
        params: query
    });
}

export function newsDetail(query) {
    return request({
        url: '/news_detail',
        method: 'get',
        params: query
    });
}

export function mediumList(query) {
    return request({
        url: '/medium_list',
        method: 'get',
        params: query
    });
}



