import { getCommon } from "@/api/common";
import { systemConfig, scrollList, navigationList } from "@/api/webSite";
import store from "@/store";
import {getInfo} from "@/api/auth";

const state = {
    common:{},
    scrollList:[],
    navigationList:[],
    loginAccount:localStorage.getItem('loginAccount')?JSON.parse(localStorage.getItem('loginAccount')):{}
}

const mutations = {
    SET_COMMON: (state, common) => {
        state.common = common;
    },
    SET_SCROLL_LIST: (state, scrollList) => {
        state.scrollList = scrollList;
    },
    SET_NAVIGATION_LIST: (state, navigationList) => {
        state.navigationList = navigationList;
    },
    SET_LOGIN_ACCOUNT: (state, loginAccount) => {
        state.loginAccount = loginAccount;
        localStorage.setItem('loginAccount',JSON.stringify(loginAccount))
    },
}

const actions = {
    // getCommon({ commit, state }) {
    //     return new Promise((resolve, reject) => {
    //         getCommon().then(response => {
    //                 const common = response;
    //                 if (!common || common.length <= 0) {
    //                     reject('getCommon: 公共数据不存在!');
    //                 }
    //                 let user_permission = common.user_permission
    //                 // console.log(common)
    //                 store.dispatch('user/setUserPermissions',user_permission);
    //                 commit('SET_COMMON', common);
    //                 resolve(common);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });
    //     });
    // },
    getCommon({ commit, state }) {
        return new Promise((resolve, reject) => {
            systemConfig().then(response => {
                    let common = response;
                    commit('SET_COMMON', common);
                    resolve(common);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getScroll({ commit, state }) {
        return new Promise((resolve, reject) => {
            scrollList().then(response => {
                let common = response;
                    commit('SET_SCROLL_LIST', common);
                    resolve(common);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getNavigation({ commit, state }) {
        return new Promise((resolve, reject) => {
            navigationList().then(response => {
                let common = response;
                    commit('SET_NAVIGATION_LIST', common);
                    resolve(common);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    setLoginAccount({ commit }, loginAccount) {
        commit('SET_LOGIN_ACCOUNT', loginAccount);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};

