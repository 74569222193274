import {setCookies} from "@/utils/auth";

const isErrorQuery = () => {
    setTimeout(()=>{
        let isError= document.getElementsByClassName("is-error");
        if (isError){
            if (isError[0].querySelector('input')){
                isError[0].querySelector('input').focus()
            }else if (isError[0].querySelector('textarea')){
                isError[0].querySelector('textarea').focus()
            }
        }
    },100);
}

const getUrlParams = (url) =>{
    let parames = {}; // 定义参数对象
    if (url){
        let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
        url.replace(pattern, ($, $1, $2) => {
            parames[$1] = $2;
        });
    }
    return parames;
}

/*
* id:user id
* type:admin|influencer
*/
const openInstagramConnect = (id,type) => {
    if (id){
        setCookies({ key: 'mediaType', seconds: false, value: { mediaType: type ? 'admin' : 'influencer', id: id} } )
        window.open(process.env.VUE_APP_URL+'/instagram/connect?ig_userid='+id)
        // window.location.href = process.env.VUE_APP_URL+'/instagram/connect?ig_userid='+id
    }
}


const priceFloat2 = (val) => {
    let tempVal = 0;
    if (val){
        tempVal = val.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')
    }
    return tempVal;
}

const numberFloat2 = (val) => {
    let tempVal = 0;
    if (val){
        tempVal = Number(val).toFixed(2)
    }
    return tempVal;
}

const compareDateTime = (cDate,cTime) => {

    if(cTime=='00:00' || !cTime) {
        cTime = '23:59:59';
    } else {
        cTime+=':59';
    }

    var odateTime1 = new Date(cDate+' '+cTime);
    var odateTime2 = new Date();

    if(odateTime1.getTime() > odateTime2.getTime()) {
        return true;
    } else {
        return false;
    }
}

// 价格格式化
function numberFormat(number, decimals, dec_point, thousands_sep) {
    /*
    * 参数说明：
    * number：要格式化的数字
    * decimals：保留几位小数
    * dec_point：小数点符号
    * thousands_sep：千分位符号
    * */
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.ceil(n * k) / k;
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export default {
    isErrorQuery,
    getUrlParams,
    openInstagramConnect,
    priceFloat2,
    numberFloat2,
    compareDateTime,
    numberFormat,
}
