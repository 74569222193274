import router from './router';
import store from './store';
import { Message } from 'element-ui';
import {isLogged, setLogged} from '@/utils/auth';
import getPageTitle from '@/utils/get-page-title';

router.beforeEach(async (to, from, next) => {
    // set page title
    document.title = getPageTitle(to.meta.title);

    if (!store.getters.common.scrollList.length){
        await store.dispatch('common/getCommon')
        await store.dispatch('common/getScroll')
        await store.dispatch('common/getNavigation')
    }
    next();

});

router.afterEach(() => {});
