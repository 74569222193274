import i18n from '@/lang';

const title = 'app_name';

export default function getPageTitle(key) {
    const hasKey = i18n.te(`config.${key}`);
    const pageTitle = i18n.t(`config.${title}`);
    if (hasKey) {
        const pageName = i18n.t(`config.${key}`);
        return `${pageTitle}-${pageName}`;
    }
    return `${pageTitle}`;
}
